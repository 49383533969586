<template>
  <div>
    <v-card
      class="mx-auto mt-10"
      max-width="1600"
      tile
    >
      <v-list-item v-for="list of lists"
        :key="list.id"
        :to="list.to"
        two-line
      >
        <v-list-item-content>
          <v-list-item-title>{{list.caption}}</v-list-item-title>
          <v-list-item-subtitle>{{list.description}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'Profile',
  components: {
  },
  data: () => ({
    lists: [
      {
        id: 1, to: { path: '/profile/settings' }, caption: 'Profile Settings', description: 'Manage user-specific UX settings, password, etc.',
      },
      {
        id: 2, to: { path: '/profile/webauthn' }, caption: 'User Passwordless Registration', description: '(BETA) Setup passwordless sign-in.',
      },
    ],
  }),
};
</script>
